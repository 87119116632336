import React, { Component } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import TextArea from '@atlaskit/textarea';
import ButtonAtl from '@atlaskit/button';
import './importCards.css'
import ProjectSelect from '../create-card/components/ProjectSelect';
import SelectPeople from '../create-card/components/SelectPeople';
import { Field } from '@atlaskit/form';
import { Querys } from '../../../database/base';
import { TimerUtils } from '../../../utility/TimerConvertUtils';
import AvatarPessoa from '../../../utility/AvatarPessoa';
import EditIcon from '@atlaskit/icon/glyph/edit'
import TextColor from '../../../utility/Color';
import CheckIcon from '@atlaskit/icon/glyph/check'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Textfield from '@atlaskit/textfield';
import { FileUploader } from "react-drag-drop-files";
import fileCSV from './template.csv'
import Skeleton from 'react-loading-skeleton';
import TrashIcon from '@atlaskit/icon/glyph/trash'
import { newMessageSuccess } from '../../../redux/actions/auth/loginActions';
import { connect } from 'react-redux';

class ImportCards extends Component {
  state = {
    textImport: ``,
    data: [],
    projectsData: [],
    people: [],
    isValid: false,
    editing: null,
    projectID: null,
    assigenedToID: null,
    file: null,
    loadingProject: true,
    loadingPeople: true,
    importing: false,
    editingItem: null
  }

  componentDidMount() {
    Querys.Project.ListAll().then(({ data }) => {
      this.setState({ projectsData: data ?? [], loadingProject: false })
    })
    Querys.Person.List().then(({ data }) => this.setState({ people: data ?? [], loadingPeople: false }))
  }

  prepareText = () => {
    const data = []
    this.state.textImport.split('\n').forEach(line => {
      const order = ['name', 'projectKey', 'user', 'plannedTime']
      let currentOrder = 0
      let obj = { editing: false }
      line.split(',').forEach(item => {
        let value = item.trim()
        if (value === '-') value = ''
        obj[order[currentOrder]] = value
        currentOrder++
        if (currentOrder >= order.length) currentOrder = order.length - 1
        obj[order[currentOrder] + "Warning"] = ''
      })
      data.push(obj)
    })
    this.setState({ ...this.validate(data) })
  }

  prepareFile = () => {
    if (this.state.file === null) {
      return
    }
    const mSelf = this
    var reader = new FileReader();
    reader.onload = function () {
      var text = reader.result;
      const data = []
      text.split('\n').forEach((line, i) => {
        if (i > 0 && line !== '') {
          const order = ['name', 'projectKey', 'user', 'plannedTime']
          let currentOrder = 0
          let obj = { editing: false }
          line.split(';').forEach(item => {
            let value = item.trim()
            if (value === '-') value = ''
            obj[order[currentOrder]] = value
            currentOrder++
            if (currentOrder >= order.length) currentOrder = order.length - 1
            obj[order[currentOrder] + "Warning"] = ''
          })
          data.push(obj)
        }
      })
      mSelf.setState({ ...mSelf.validate(data) })
    };
    reader.readAsText(this.state.file);
  }

  validate = (data) => {
    let { people } = this.state
    if (this.props.participants && this.props.participants.length > 0) {
      people = this.props.participants
    }
    let isValid = true
    data.forEach(item => {
      item.projectKeyWarning = ''
      item.userWarning = ''
      item.nameWarning = ''
      item.plannedTimeWarning = ''

      if (item.projectKey) {
        item.project = (this.state.projectsData ?? []).find(x => x.Nome.toLowerCase() === item.projectKey.toLowerCase() || x.ProjectKey.toLowerCase() === item.projectKey.toLowerCase())
        if (!item.project) {
          item.projectKeyWarning = 'td-warning'
          isValid = false
        }
      }
      if (item.user) {
        item.person = people.find(x => x.name.toLowerCase() === item.user.toLowerCase() || x.email.toLowerCase() === item.user.toLowerCase())
        if (!item.person) {
          item.userWarning = 'td-warning'
          isValid = false
        }
      }
      if (!item.name || item.name === '') {
        item.nameWarning = 'td-warning'
        isValid = false
      }
      if (item.plannedTime) {
        item.plannedTimeValue = parseInt(TimerUtils.tempoToMinutos(item.plannedTime))
        if (item.plannedTimeValue === -1) {
          item.plannedTimeWarning = 'td-warning'
          isValid = false
        }
      } else {
        item.plannedTimeValue = null
      }
    })
    return { data, isValid }
  }

  handleChange = (file) => {
    this.setState({ file })
  };

  import = () => {
    this.setState({ importing: true })
    const cards = this.state.data.map(item => {
      return {
        projectKey: item.projectKey ?? null,
        projectID: item.project?.Codigo ?? null,
        name: item.name,
        plannedTime: item.plannedTime ? item.plannedTimeValue : null,
        assigenedToID: item.user ? item.person?.id ?? null : null
      }
    })
    Querys.Card.Import(this.props.destiny, cards).then(({ data }) => {
      if (this.props.onImported)
        this.props.onImported(data)
      this.setState({ importing: false, data: [] })
      this.props.newMessageSuccess('Importado com sucesso')
    })
  }

  confirmEditing = () => {
    const item = this.state.editingItem
    item.name = this.state.editing.name
    item.plannedTime = this.state.editing?.plannedTime ?? ''
    if (item.plannedTime !== '') {
      item.plannedTimeValue = parseInt(TimerUtils.tempoToMinutos(this.state.editing.plannedTime))
    }
    item.projectKey = this.state.editing.projectKey
    item.user = this.state.editing.user
    item.editing = false
    this.setState({ ...this.validate(this.state.data), editing: null, editingItem: null })
  }

  render() {
    let { people } = this.state
    if (this.props.participants && this.props.participants.length > 0) {
      people = this.props.participants
    }
    return (
      <div>
        {(this.state.loadingPeople || this.state.loadingProject) &&
          <>
            <Skeleton height={40} count={1} style={{ marginBottom: '1rem' }} />
          </>}
        {!this.state.loadingPeople && !this.state.loadingProject &&
          <>
            <Row>
              <Col md='12'>
                <h2>Importar</h2>
                <p>A importação de cards segue a seguinte ordem: <b>descrição do card, projeto <small>(nome ou chave do projeto)</small>, responsável <small>(nome ou email do usuário)</small>, tempo planejado</b>. <br />Apenas a descrição do card é obrigatória.</p>
              </Col>
              <Col md='6'>
                <Card>
                  <CardBody>
                    <TextArea minimumRows={10} maxHeight='120px' value={this.state.textImport} onChange={(e) => this.setState({ textImport: e.target.value })}
                      placeholder={`Exemplo:
Reunião de alinhamento, Projeto Minzi, João da Silva, 0:30
Criação de planilha, Projeto Minzi, Maria Caroline, 1:00
`}
                    />
                    <div className='d-flex justify-content-between'>
                      <div></div>
                      <ButtonAtl appearance='primary' className='atl-button mt-1' onClick={this.prepareText} isDisabled={this.state.textImport.length === 0}>Validar</ButtonAtl>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6'>
                <Card>
                  <CardBody>

                    <FileUploader
                      classes='drop_area drop_zone'
                      handleChange={this.handleChange}
                      name="file" types={["CSV"]}
                      label='Selecione ou arraste o arquivo aqui' >
                      <div className='drop-file-area'>
                        {this.state.file?.name ?
                          <>
                            {`Arquivo selecionado: ` + this.state.file.name}
                          </>
                          :
                          'Selecione ou arraste o arquivo aqui'}
                      </div>
                    </FileUploader>
                    <div className='d-flex justify-content-between'>
                      <div className='pt-1'>
                        <a target='_blank' href={fileCSV}>Clique aqui para fazer o download do template de exemplo</a>
                      </div>
                      <ButtonAtl appearance='primary' className='atl-button mt-1' onClick={this.prepareFile} isDisabled={this.state.file === null}>Validar</ButtonAtl>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.data.length > 0 &&
              <Row>
                <Col md='12'>
                  <div className='import-table-scroll'>
                    <table className='w-100 import-table'>
                      <thead>
                        <tr>
                          <th style={{ width: '35px' }}></th>
                          <th>
                            <Field label="Nome" name="project-select">
                              {({ fieldProps }) => { }}
                            </Field>
                          </th>
                          <th>
                            <Field label="Tempo planejado" name="planned-time">
                              {({ fieldProps }) => { }}
                            </Field>
                          </th>
                          <th style={{ width: '355px', paddingRight: '1rem' }}>
                            <ProjectSelect
                              disableSprint
                              onChange={(projectID) => {
                                this.setState({ projectID })
                              }}
                            />
                            <div className='d-flex justify-content-between'>
                              <div></div>
                              <div className='d-flex'>
                                <ButtonAtl className='atl-button mt-1 mr-1' onClick={() => {
                                  this.state.data.forEach(item => {
                                    item.projectKey = ''
                                  })
                                  this.setState({ ...this.validate(this.state.data) })
                                }}>Limpar todos</ButtonAtl>
                                <ButtonAtl className='atl-button mt-1 mb-1' onClick={() => {
                                  if (this.state.projectID > 0) {
                                    const p = this.state.projectsData.find(x => x.Codigo === this.state.projectID)
                                    this.state.data.forEach(item => {
                                      item.projectKey = p.ProjectKey
                                    })
                                    this.setState({ ...this.validate(this.state.data) })
                                  }
                                }}>
                                  Aplicar em todos
                                </ButtonAtl>
                              </div>
                            </div>
                          </th>
                          <th>
                            <SelectPeople defaultData={people} label='Responsável' isClearable onChange={assigenedToID => this.setState({ assigenedToID })} />
                            <div className='d-flex justify-content-between'>
                              <div></div>
                              <div className='d-flex'>
                                <ButtonAtl className='atl-button mt-1 mr-1' onClick={() => {
                                  this.state.data.forEach(item => {
                                    item.user = ''
                                  })
                                  this.setState({ ...this.validate(this.state.data) })
                                }}>Limpar todos</ButtonAtl>
                                <ButtonAtl className='atl-button mt-1 mb-1' onClick={() => {
                                  if (this.state.assigenedToID > 0) {
                                    const p = people.find(x => x.id === this.state.assigenedToID)
                                    this.state.data.forEach(item => {
                                      item.user = p.email
                                    })
                                    this.setState({ ...this.validate(this.state.data) })
                                  }
                                }}>
                                  Aplicar em todos
                                </ButtonAtl>
                              </div>
                            </div>
                          </th>
                          <th style={{ width: '75px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((item, index) => (
                          <>
                            {!item.editing &&
                              <tr key={`line-noEditing-${index}`}>
                                <td className='pl-1'></td>
                                <td className={item.nameWarning}>{item.name}</td>
                                <td className={item.plannedTimeWarning}>{item.plannedTimeValue > 0 ? TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.plannedTimeValue) : item.plannedTime}</td>
                                <td className={item.projectKeyWarning}>
                                  {item.project &&
                                    <div className='project-select-item' style={{ backgroundColor: item.project.Color, color: TextColor(item.project.Color) }}>{item.project.Nome} ({item.project.NomeCliente})</div>}
                                  {!item.project && item.projectKey}
                                </td>
                                <td className={`${item.userWarning}`}>
                                  <div className='d-flex'>
                                    {item.person && <AvatarPessoa nome={item.person.name} codigo={item.person.id} />}
                                    {item.person?.name ?? item.user}
                                  </div>
                                </td>
                                <td className='text-center'>
                                  <ButtonAtl className='atl-button' iconAfter={<EditIcon />} onClick={() => {
                                    if (this.state.editingItem !== null) {
                                      this.confirmEditing()
                                    }
                                    item.editing = true
                                    this.setState({ ...this.state, editing: { ...item }, editingItem: item })
                                  }} />
                                  <ButtonAtl className='atl-button' style={{ marginLeft: '3px' }} iconAfter={<TrashIcon />} onClick={() => {
                                    this.state.data.splice(index, 1)
                                    this.setState({ ...this.validate(this.state.data) })
                                  }} />
                                </td>
                              </tr>}
                            {item.editing &&
                              <tr key={`line-editing-${index}`}>
                                <td className='pl-1'></td>
                                <td className={item.nameWarning}>
                                  <Textfield className='w-100' value={this.state.editing.name} onChange={e => this.setState({ editing: { ...this.state.editing, name: e.target.value } })} />
                                </td>
                                <td className={item.plannedTimeWarning}>
                                  <Textfield
                                    value={this.state.editing.plannedTime}
                                    onChange={e => {
                                      this.setState({ editing: { ...this.state.editing, plannedTime: e.target.value } })
                                    }}
                                  />
                                </td>
                                <td className={item.projectKeyWarning}>
                                  <ProjectSelect
                                    disableSprint disableHeader
                                    onChange={(projectID) => {
                                      const project = this.state.projectsData.find(x => x.Codigo === projectID)
                                      this.setState({ editing: { ...this.state.editing, projectKey: project.ProjectKey } })
                                    }}
                                  />
                                </td>
                                <td className={`${item.userWarning}`}>
                                  <SelectPeople label='Responsável' isClearable
                                    disableHader
                                    defaultData={people}
                                    onChange={assigenedToID => {
                                      const person = people.find(x => x.id === assigenedToID)
                                      this.setState({ editing: { ...this.state.editing, user: person.email } })
                                    }} />
                                </td>
                                <td className='text-center'>
                                  <div className='d-flex'>
                                    <ButtonAtl className='atl-button' style={{ marginRight: '3px', marginLeft: '3px' }} iconAfter={<CheckIcon />} onClick={this.confirmEditing} />
                                    <ButtonAtl className='atl-button' iconAfter={<CrossIcon />} onClick={() => {
                                      item.editing = false
                                      this.setState({ ...this.state })
                                    }} />
                                  </div>
                                </td>
                              </tr>}
                          </>

                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col md='12'>
                  <div className='d-flex justify-content-between'>
                    <div></div>
                    <ButtonAtl className='atl-button mt-1 mb-1' appearance='primary' isDisabled={!this.state.isValid || this.state.importing}
                      onClick={this.import}>
                      IMPORTAR
                    </ButtonAtl>
                  </div>
                </Col>
              </Row>}
          </>}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, {
  newMessageSuccess
})(ImportCards)